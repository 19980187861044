<!--
 * @Author: your name
 * @Date: 2021-03-04 16:13:32
 * @LastEditTime: 2021-03-10 11:06:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\category\appraiseItem.vue
-->
<template>
  <div>
    <div v-for="(item,index) in evaluateList " :key="index" class="comment-item">
      <div class="user">
        <div class="name">
          {{ item.hzxm }}
        </div>
        <Stars :star="Number(item.pjfz)" />
      </div>
      <div class="con">
        {{ item.pj===undefined?'':item.pj }}
      </div>
      <div class="time">
        {{ item.pjsj==undefined?'':item.pjsj }}
      </div>
    </div>
  </div>
</template>
<script>
import Stars from '../../components/category/star'
export default {
components:{
  Stars
},
props:{
   evaluateList: {
        type: Array,
        default: () => ([])
      }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
div {
  box-sizing: border-box;
}
.comment-item {
  margin: 0 -32px;
  padding: 24px 32px;
  font-size: 26px;
  line-height: 33px;
  color: @middle-font-color;
  border-bottom: 1px solid @color-EE;

  .user {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .name {
      flex: 1;
      font-weight: bold;
      color: @dark-font-color;
    }
  }

  .con {
    margin-bottom: 24px;
  }

  .time {
    line-height: 28px;
    font-size: 22px;
    text-align: right;
    color: @light-font-color;
  }
}
</style>
