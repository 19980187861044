<!--
 * @Author: your name
 * @Date: 2021-03-04 16:26:00
 * @LastEditTime: 2021-03-10 10:29:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\category\star.vue
-->
<template>
  <div>
    <div class="stars">
      <!--      <div v-for="(index) in star" :key="index">-->
      <!--        <img class="img" :src="require('../../assets/img/star.png')">-->
      <!--      </div>-->
      <van-rate
        v-model="star"
        :size="15"
        allow-half
        color="#ffd21e"
        void-icon="star"
        void-color="#eee"
      />
    </div>
  </div>
</template>
<script>
export default {
  props:{
    star: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style scoped lang='less'>
div {
  box-sizing: border-box;
}
.stars {
  display: flex;
  .img {
    margin-left: 6px;
    width: 16.6px;
    height: 15.87px;
  }
}
</style>
