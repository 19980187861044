<!--
 * @Author: your name
 * @Date: 2021-03-10 10:58:04
 * @LastEditTime: 2021-03-12 17:16:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\doctor\evaluate.vue
-->
<template>
  <div>
    <load-more :data-source="evaluateList" :total="total" :loading="loading" @load-more="onLoad">
      <AppraiseItem :evaluate-list="evaluateList" />
    </load-more>
  </div>
</template>
<script>
import LoadMore from '../../components/load-more/LoadMore.vue'
import AppraiseItem from '../../components/category/appraiseItem'
import {appointModel} from '../../api/appoint'
export default {
  components: { LoadMore,AppraiseItem },

data(){
  return{
    evaluateList:[],
    page:1,
    pageSize:10,
    loading: false,
    total:0
  }
},
mounted(){
  this.getList()
},
methods:{
  getList(){
    const {yljgdm,ksdm,ysgh}=this.$route.query
     let params={
        page:this.page,
        pageSize:this.pageSize,
        yljgdm:yljgdm,
        ksdm:ksdm,
        ysgh:ysgh

      }
    appointModel.getWrite(params).then(res=>{
          this.evaluateList=res.data||[]
          this.total=res.count

      })
  },
        onLoad(){
        const {yljgdm,ksdm,ysgh}=this.$route.query
        this.loading = true
        this.page+=1
        let params = {
          page: this.page,
          pageSize: 5,
          yljgdm,
          ksdm,
          ysgh
        }
         appointModel.getWrite(params).then( res =>{
            this.evaluateList.push(...res.data)
            this.loading = false
            this.total=res.count

        })
      }
}
}
</script>
<style scoped lang='less'>
/deep/.pull-class{
  padding: 0;
}
</style>
